import React, { useState, useCallback, useMemo } from 'react';
import Particles from 'react-tsparticles';
import { loadSlim } from 'tsparticles-slim';

const ParticlesBackground = () => {
    const [init, setInit] = useState(false);

    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        await loadSlim(engine);
        setInit(true);
    }, []);

    const particlesLoaded = useCallback((container) => {
        console.log(container);
    }, []);

    const options = useMemo(() => ({
        autoPlay: true,
        background: {
            color: { value: "transparent" },
        },
        backgroundMask: {
            composite: "destination-out",
            cover: { color: { value: "#fff" }, opacity: 1 },
            enable: false,
        },
        clear: true,
        fpsLimit: 120,
        interactivity: {
            detectsOn: "window",
            events: {
                onClick: { enable: true, mode: "push" },
                onHover: {
                    enable: true,
                    mode: "repulse",
                    parallax: { enable: true, force: 60, smooth: 10 },
                },
                resize: { delay: 0.5, enable: true },
            },
            modes: {
                attract: {
                    distance: 200,
                    duration: 0.4,
                    easing: "ease-out-quad",
                    factor: 1,
                    maxSpeed: 50,
                    speed: 1,
                },
                bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                },
                grab: {
                    distance: 400,
                    links: { opacity: 1 },
                },
                push: { quantity: 4 },
                repulse: {
                    distance: 200,
                    duration: 0.4,
                    factor: 100,
                    speed: 1,
                    maxSpeed: 50,
                    easing: "ease-out-quad",
                },
            },
        },
        particles: {
            color: { value: "#116711" },
            links: {
                color: "#116711",
                distance: 150,
                enable: true,
                opacity: 0.4,
                width: 1,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: { default: "out" },
                random: false,
                speed: 2,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                    width: 1920,
                    height: 1080,
                },
                value: 100,
            },
            opacity: {
                value: { min: 0.1, max: 0.5 },
                animation: {
                    enable: true,
                    speed: 3,
                    sync: false,
                },
            },
            shape: { type: "circle" },
            size: {
                value: { min: 1, max: 10 },
                animation: {
                    enable: true,
                    speed: 20,
                    sync: false,
                },
            },
        },
        detectRetina: true,
    }), []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={options}
            style={{ position: 'absolute', width: '100%', height: '100%', zIndex: -1 }}
        />
    );
};

export default ParticlesBackground;
